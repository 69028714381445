import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import Clients from '../components/sections/Clients';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
// import Pricing from '../components/sections/Pricing';
// import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroFull invertColor className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <FeaturesSplit invertMobile imageFill bottomDivider />
        <FeaturesTiles bottomDivider/>
        <Testimonial />
        {/* <Pricing topDivider pricingSlider />
        <Cta hasBgColor invertColor split /> */}
      </React.Fragment>
    );
  }
}

export default Home;
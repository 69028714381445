import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
      <li>
          <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/company/adiova/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>LinkedIn</title>
              <g>
                <path d="M14.4,0 L1.6,0 C0.72,0 0,0.72 0,1.6 L0,14.4 C0,15.28 0.72,16 1.6,16 L14.4,16 C15.28,16 16,15.28 16,14.4 L16,1.6 C16,0.72 15.28,0 14.4,0 Z M4.8,13.6 L2.4,13.6 L2.4,6.4 L4.8,6.4 L4.8,13.6 Z M3.6,5.04 C2.8,5.04 2.16,4.4 2.16,3.6 C2.16,2.8 2.8,2.16 3.6,2.16 C4.4,2.16 5.04,2.8 5.04,3.6 C5.04,4.4 4.4,5.04 3.6,5.04 Z M13.6,13.6 L11.2,13.6 L11.2,9.36 C11.2,8.72003137 10.64,8.16 10,8.16 C9.36,8.16 8.8,8.72003137 8.8,9.36 L8.8,13.6 L6.4,13.6 L6.4,6.4 L8.8,6.4 L8.8,7.36 C9.2,6.72 10.08,6.24 10.8,6.24 C12.32,6.24 13.6,7.52 13.6,9.04 L13.6,13.6 Z"></path>
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/adiovapartners/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Twitter</title>
              <path
                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/adiovapartners/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Facebook</title>
              <path
                d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;
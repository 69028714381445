import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Optimum solution. Every time.',
      paragraph: 'We analyze your problem not only from a technical standpoint but also from a business standpoint. This mindset enables us to find and execute the optimum solution for you.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Digital transformation strategy
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                    We believe your digital transformation strategy should ensure the best ROI for your business. Whether it's automating a manual process, adopting cloud computing, or introducing machine learning and data science, we help you decide the best strategy for the short and long terms. We understand business as much as we understand technology.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/digital-transformation-strategy.svg')}
                    // className="has-shadow"
                    alt="Features split 01"
                    width={528}
                    height={396} />
                  {/* <div style={img01Style}>
                    <Image
                      src={require('./../../assets/images/features-split-top-01.svg')}
                      alt="Features split top 01"
                      width={538}
                      height={396} />
                  </div> */}
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                    Application development
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="100" data-reveal-container=".split-item">
                    So, you want to develop an application for your business. But there are hundreds of programming languages, thousands of operating systems, and millions of libraries and frameworks! Don't panic! Our consultants will choose the proper technology stack and develop the perfect application to satisfy your business need.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/application-development.svg')}
                    // className="has-shadow"
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  {/* <div style={img02Style}>
                    <Image
                      src={require('./../../assets/images/features-split-image.svg')}
                      alt="Features split top 02"
                      width={528}
                      height={396} />
                  </div> */}
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Digital migration projects
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                    In this digital age, you may already have some digital solutions integrated into your business operations. But you want to migrate to better solutions to increase your margin and stay ahead on the game! We can develop web/mobile versions of your desktop applications, migrate on-premises applications to the cloud, and introduce machine learning and data science to your existing applications.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/digital-migration-projects.svg')}
                    // className="has-shadow"
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  {/* <div style={img03Style}>
                    <Image
                      src={require('./../../assets/images/features-split-top-03.svg')}
                      alt="Features split top 03"
                      width={528}
                      height={396} />
                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const img01Style = {
  position: 'absolute',
  bottom: '-4.8%',
  right: '-2-27%'
}

const img02Style = {
  position: 'absolute',
  top: '-5.05%',
  left: '-6.06%'
}

const img03Style = {
  position: 'absolute',
  bottom: '-0.5%',
  right: '-16.67%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
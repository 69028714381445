import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap has-bg-color',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Our clients love us. You will too.',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-01.jpg')}
                        alt="Testimonial 01"
                        width={48}
                        height={48} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — When one of our Web App development projects went beyond the proposed timeline, we decided to hire a consultant to complete the project. Fortunately, we hired one from Adiova. He led the successful project completion within the next three months and saved us $20,000 by suggesting alternative solutions. We just hired him for our next project!
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name">
                      John B.
                    </span>
                    <span> - </span>
                    <span className="testimonial-item-link">
                      <a href="https://www.linkedin.com/in/john-henry-brown/" target="_blank">JPMorgan Chase & Co.</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-02.jpg')}
                        alt="Testimonial 02"
                        width={48}
                        height={48} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      —  In my previous company, I worked with a Consultant from Adiova. I liked his technical and interpersonal skills. He was proactive in finishing his assignments and learned new skills when required. My team members also loved him for his kindness. I want to work with him again or any Adiova Consultants in my future projects.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name">
                      Amy W.
                    </span>
                    <span> - </span>
                    <span className="testimonial-item-link">
                      <a href="https://www.linkedin.com/in/amywang65/" target="_blank">Amazon</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-03.jpg')}
                        alt="Testimonial 03"
                        width={48}
                        height={48} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — I enjoyed working with a Software Engineering Consultant from Adiova in one of our customer-facing products at Citi. He was there to help us with our Java development. I was surprised with the quality that he brought in our product development by introducing a weekly tech talk session. He was a role model for other engineers in our company. I would love to work with him again.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name">
                      Emil G.
                    </span>
                    <span> - </span>
                    <span className="testimonial-item-link">
                      <a href="https://www.linkedin.com/in/emil-goldsmith-5b722b1b2/" target="_blank">Citi</a>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      
      {/* hello@adiova.partners */}
        

      {/* hello@{window.location.hostname.substring(4)} */}

      {/* <a target="_blank" rel="noopener noreferrer" style= {{margin: '0 auto', display:'block', textAlign: 'auto'}}
        href="https://www.google.com/maps/place/Wild+Basin+II,+108+Wild+Basin+Rd+S+%23250,+Austin,+TX+78746/@30.3067972,-97.8272307,19z/data=!3m1!4b1!4m13!1m7!3m6!1s0x865b3577bd7967c5:0xf36d5b72967f677c!2sWild+Basin+II,+108+Wild+Basin+Rd+S+%23250,+Austin,+TX+78746!3b1!8m2!3d30.3067972!4d-97.8266822!3m4!1s0x865b3577bd7967c5:0xf36d5b72967f677c!8m2!3d30.3067972!4d-97.8266822">
        108 Wild Basin Road S #250<br></br>Austin, TX 78746</a> */}
      
      <a style= {{margin: '0 auto', display:'block', textAlign: 'auto'}} href="tel:+1 (307) 429-0690">+1 (307) 429-0690</a>
      <a style= {{margin: '0 auto', display:'block', textAlign: 'auto'}} href="mailto:hello@adiova.partners">hello@adiova.partners</a>
      
      {/* <ul className="list-reset">
        <li>
          <Link to="/contact/">Contact</Link>
        </li>
        <li>
          <Link to="/about-us/">About us</Link>
        </li>
        <li>
          <Link to="/faqs/">FAQ's</Link>
        </li>
        <li>
          <Link to="/support/">Support</Link>
        </li>
      </ul> */}
    </nav>
  );
}

export default FooterNav;